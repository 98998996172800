import logo from "./logo.svg";
import "./App.css";
import moment from "moment";
import Select from "react-select";
// import { Button } from 'antd';
// import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import GameResultTable from "./JS/game_result_table";
import trackVisitor from "./utilities/tracker";
import AdvertisementComponent from "./utilities/advertismentComponent";
import { Helmet } from "react-helmet";
const momenttz = require("moment-timezone");

function App() {
  // const todayDate = moment(new Date()).tz('Asia').format('LLL')
  var todayDate = moment().tz("Asia/Kolkata").format("LLL");
  const currentDate = moment().tz("Asia/Kolkata").format("YYYY-MM-DD");
  const prevDate = moment()
    .subtract(1, "days")
    .tz("Asia/Kolkata")
    .format("YYYY-MM-DD");
  const [data, setData] = useState([]); // State to store data fetched from backend
  const [datagame, setDataFor] = useState([]);
  const [currentTime, setCurrentTime] = useState(moment().format("HH:mm:ss")); // State for current time with seconds

  // Update time every second
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment().format("HH:mm:ss")); // Update the current time every second
    }, 1000); // 1000 ms = 1 second

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []);

  useEffect(() => {
    trackVisitor();
  });

  useEffect(() => {
    fetch("https://api.sattakingvip.co.in/getData", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        game_name: "",
        curr_date: currentDate,
        prev_date: prevDate,
        open_time: "market_sunday_time_open",
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        // Sort data based on open_time
        const sortedData = json.sort((a, b) => {
          const timeA = moment(a.open_time, "HH:mm");
          const timeB = moment(b.open_time, "HH:mm");
          return timeA.diff(timeB);
        });

        // Set sorted data into state
        setData(sortedData);
      })
      .catch((error) => console.error(error));
  }, [currentDate, prevDate]);

  useEffect(() => {
    if (data?.length > 0) {
      const currentMoment = moment(currentTime, "HH:mm:ss"); // Modified to handle HH:mm:ss format
  
      const processedData = data.map((item) => {
        const itemTime = moment(item.open_time, "HH:mm");
        const resultAvailable = item?.curr_date?.result ? true : false;
  
        return {
          gameName: item.game_name,
          result: resultAvailable ? item?.curr_date?.result : "wait",
          openTime: item.open_time,
          isAvailable: resultAvailable,
          itemTime: itemTime,
        };
      });
  
      const sortedProcessedData = processedData.sort((a, b) => 
        a.itemTime.diff(b.itemTime)
      );
  
      const availableResults = sortedProcessedData.filter((item) => item.isAvailable);
      const upcomingRecords = sortedProcessedData.filter((item) => !item.isAvailable);
  
      let recordsToDisplay = [];
  
      if (availableResults.length > 0) {
        recordsToDisplay = [...availableResults];
  
        const lastAvailableIndex = sortedProcessedData.indexOf(
          availableResults[availableResults.length - 1]
        );
        const nextRecord = sortedProcessedData[lastAvailableIndex + 1];
        if (nextRecord) {
          recordsToDisplay.push(nextRecord);
        }
      } else {
        recordsToDisplay = [...upcomingRecords.slice(0, 3)];
      }
  
      if (recordsToDisplay.length > 3) {
        recordsToDisplay = recordsToDisplay.slice(-3).reverse();
      }
  
      // Move "wait" items to the top
      recordsToDisplay.sort((a, b) => (a.result === "wait" ? -1 : 1));
  
      setDataFor(recordsToDisplay);
    }
  }, [data, currentTime]); // Dependencies ensure useEffect runs when data or time changes

  return (
    <>
      <div className="App">
        {/* seo setup start */}
        <Helmet>
          <title></title>
          <meta
            name="description"
            content="satta-king-satta,satta-company,sattasport, satta sport, sattaking"
          />
          <meta
            name="Keywords"
            content="atta-king-org,atta king org, sattakingreal, satta king real, sattaking real, Satta King, Satta King live result, Satta king online result, Satta king online, Satta king result today, Gali result, Desawar result, Faridabad result, Gaziyabad result, Satta matka king, Satta Bazar, Black satta king, Satta king 2017, satta king 2018, Gali Leak Number, Gali Single Jodi, Black Satta Result, Desawar Single Jodi, Satta king up, Satta king desawar, Satta king gali, Satta king 2019 chart, Satta baba king, Satta king chart, Gali live result, Disawar live result, Satta Number, Matka Number, Satta.com, Satta Game, Gali Number, Delhi Satta king,"
          />
          <link rel="canonical" href="https://satta-king-org.co" />
        </Helmet>
        {/* seo setup end */}
        <h6 className="appHead">👑SATTA KING UP👑</h6>

        <div className="banner">
          <artical className="text-center artical">
            <p>
              🚨🎯SATTAKING UP - SATTA KING DISAWAR, SATTA KING UP, GALI SATTA KING,
              SATTA KING 786, SATTA KING FAST, SATTA KING DELHI🚨, SATTA KING
              CHART, TODAY SATTA RESULT, SATTA KING ONLINE, LIVE SATTA RESULT💹👑
            </p>
          </artical>

          <h4>{currentDate}</h4>
          {/* Display the current time */}
          <h4>Current Time: {currentTime}</h4>

          {datagame?.map((todayData, index) => (
            <div key={index}>
              <h2 className="mb-2">{todayData?.gameName}</h2>
              <h3 className="mb-1 blinking-text">
                {" "}
                {todayData?.result || "WAIT"}
              </h3>
              <button className="mb-4 ">
                {todayData?.gameName} Records 2024
              </button>
            </div>
          ))}
        </div>
      </div>
      <AdvertisementComponent type="odd" />
      <GameResultTable dayGameData={data} />
    </>
  );
}

export default App;
